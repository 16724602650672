import Head from 'next/head'
import { useRouter } from 'next/router'
import Image from 'next/image'
import Button from '@/components/base/Button'
import styles from '@/styles/Error.module.css'

const Error404 = () => {
    const router = useRouter()
    return (
        <>
            <Head>
                <title>
                    {`404 Not Found | ${process.env.NEXT_PUBLIC_APP_NAME}`}
                </title>
            </Head>
            <div>
                <div className="fixed top-0 left-0 right-0 h-full w-full">
                    <div className={`${styles.imagePosition}`}>
                        <div className="-mt-[100px] h-[524px] w-[673px] opacity-30">
                            <Image
                                src="/storage/logo.png"
                                alt={process.env.NEXT_PUBLIC_APP_NAME}
                                width={673}
                                height={524}
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <h1 className={styles.heading}>OOPS!</h1>
                    <h2 className={styles.subHeading}>
                        We looked everywhere and couldn&apos;t find that page.
                    </h2>
                    <p className={styles.description}>
                        This might be because: You have typed the web address
                        incorrectly, or the page you were looking for may have
                        been moved, updated or deleted.
                    </p>
                    <div className="mt-8 flex items-center justify-center">
                        <Button
                            text="Back to Home"
                            type="button"
                            size="md"
                            variant="primary"
                            click={() => router.push(`/`)}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

Error404.error = true
export default Error404
